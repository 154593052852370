import { Colours, Dimensions, FontFaceDetails, GlobalStyles } from '../../../types/ThemeConfigTypes';
import {
  FontStyleType,
  FontStyleObjectType,
  FontWeightObjectType,
  LetterSpacingObjectType,
  LinkStyleObjectType,
  TypographyVariants
} from '../../../types/TypographyTypes';

export const FONT_STYLE_NORMAL_CONST: FontStyleType = 'normal';
export const FONT_STYLE_ITALIC_CONST: FontStyleType = 'italic';

export const FONT_WEIGHT_BOLD: FontWeightObjectType = {
  fontWeight: 700
};

export const FONT_WEIGHT_MEDIUM: FontWeightObjectType = {
  fontWeight: 500
};

export const FONT_WEIGHT_NORMAL: FontWeightObjectType = {
  fontWeight: 450
};

export const FONT_STYLE_NORMAL: FontStyleObjectType = {
  fontStyle: FONT_STYLE_NORMAL_CONST
};

export const FONT_STYLE_ITALIC: FontStyleObjectType = {
  fontStyle: FONT_STYLE_ITALIC_CONST
};

export const LETTER_SPACING_DEFAULT: LetterSpacingObjectType = {
  letterSpacing: 0
};

export const LETTER_SPACING_QUARTER: LetterSpacingObjectType = {
  letterSpacing: 0.25
};

export const LINK_STYLE: LinkStyleObjectType = {
  textDecorationLine: 'underline'
};

export const getThemeColors = (): Colours => {
  return {
    text: {
      text: '#161616',
      textWeak: '#393939',
      textWeakest: '#8D8D8D',
      textInverse: '#FFFFFF',
      textInverseWeak: '#78A9FF',
      textLink: '#0B5FFF',
      textError: '#FA4D56',
      textInput: '#161616',
      textInputLabel: '#6F6F6F',
      textInputIcon: '#0B5FFF',
      textInputDisabled: '#A8A8A8',
      textSuccess: '#42BE65',
      textLinkLight: '#4589FF',
      textWarning: '#F1C21B',
      textInputFocus: '#0B5FFF',
      textPending: '#FFB46E',
      textFocus: '#0B5FFF',
      textLandingPage: '#161616',
      textLandingPageWeakest: '#8D8D8D',
      textActiveDark: '#002D9C',
      textActiveDarkReferAFriend: '#002D9C'
    },
    backgrounds: {
      background: '#F4F4F4',
      backgroundAlt: '#FFFFFF',
      backgroundAltInverse: '#0530AD',
      backgroundBackdrop: 'RGBA(0,0,0,0.5)',
      backgroundError: '#FFF1F1',
      backgroundSuccess: '#DEFBE6',
      backgroundWarning: '#FFF8E1',
      backgroundInputDisabled: '#F4F4F4',
      backgroundAltInverseWeak: '#EDF5FF',
      backgroundInfo: '#161616',
      backgroundAltInverseStrong: '#D0E2FF',
      backgroundPrimary: '#FF6B00',
      backgroundSecondary: '#0B5FFF',
      backgroundPending: '#FFEAD7',
      backgroundLandingPage: '#F4F4F4',
      backgroundActiveLightest: '#EDF5FF'
    },
    borders: {
      borderError: '#FA4D56',
      borderSuccess: '#42BE65',
      borderWarning: '#F1C21B',
      borderInput: '#DADADA',
      borderSelectionHover: '#C6C6C6',
      borderInputActive: '#0B5FFF',
      borderPending: '#FFB46E',
      borderInputFocus: '#0B5FFF'
    },
    buttons: {
      primary: {
        bg: 'linear-gradient(96.05deg, #F03EA6 8.48%, #FF6B00 91.01%)',
        text: '#FFFFFF',
        border: 'linear-gradient(96.05deg, #F03EA6 8.48%, #FF6B00 91.01%)',
        icon: '#FFFFFF',
        hoverBg: 'linear-gradient(94deg, #F03EA6 35.24%, #FF6B00 64.86%)',
        hoverText: '#FFFFFF',
        hoverBorder: 'linear-gradient(94deg, #F03EA6 35.24%, #FF6B00 64.86%)',
        hoverIcon: '#FFFFFF',
        focusBg: 'linear-gradient(90deg, #F03EA6 5%, #FF6B00 95%)',
        focusText: '#FFFFFF',
        focusBorder: '#FF6B00',
        focusIcon: '#FFFFFF'
      },
      secondary: {
        bg: '#0B5FFF',
        text: '#FFFFFF',
        border: '#0B5FFF',
        icon: '#FFFFFF',
        hoverBg: '#0530AD',
        hoverText: '#FFFFFF',
        hoverBorder: '#0530AD',
        hoverIcon: '#FFFFFF',
        focusBg: '#0530AD',
        focusText: '#FFFFFF',
        focusBorder: '#0530AD',
        focusIcon: '#FFFFFF'
      },
      outline: {
        bg: '#FFFFFF',
        text: '#161616',
        border: '#DADADA',
        icon: '#0B5FFF',
        hoverBg: '#FFFFFF',
        hoverText: '#161616',
        hoverBorder: '#0B5FFF',
        hoverIcon: '#0B5FFF',
        focusBg: '#EDF5FF',
        focusText: '#161616',
        focusBorder: '#0B5FFF',
        focusIcon: '#0B5FFF'
      },
      text: {
        text: '#161616',
        icon: '#0B5FFF',
        hoverText: '#0B5FFF',
        hoverIcon: '#0B5FFF',
        focusText: '#0B5FFF',
        focusBorder: '#0530AD',
        focusIcon: '#0B5FFF'
      }
    },
    scroll: {
      thumbBackground: '#C6C6C6',
      trackBackground: '#F4F4F4'
    }
  };
};

const getThemeDimensions = (): Dimensions => {
  return {
    buttons: {
      borderRadius: 100
    }
  }
};

export const getFontFaceDetails = (): FontFaceDetails[] => {
  return [
    {
      fontName: 'CircularXX',
      fileName: 'CircularXX-Bold.otf',
      fontWeight: '700',
      ...FONT_STYLE_NORMAL
    },
    {
      fontName: 'CircularXX',
      fileName: 'CircularXX-BoldItalic.otf',
      fontWeight: '700',
      ...FONT_STYLE_ITALIC
    },
    {
      fontName: 'CircularXX',
      fileName: 'CircularXX-Medium.otf',
      fontWeight: '500',
      ...FONT_STYLE_NORMAL
    },
    {
      fontName: 'CircularXX',
      fileName: 'CircularXX-Book.otf',
      fontWeight: '450',
      ...FONT_STYLE_NORMAL
    },
    {
      fontName: 'CircularXX',
      fileName: 'CircularXX-BookItalic.otf',
      fontWeight: '450',
      ...FONT_STYLE_ITALIC
    }
  ];
};

export const getGlobalStyles = () => {
  return {
    body: {
      margin: '0px',
      fontFamily: 'CircularXX, Helvetica, Roboto, Arial, sans-serif',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale'
    }
  };
};

export const getThemeTypography = (): TypographyVariants => {
  return {
    fontFamily: 'CircularXX, Helvetica, Roboto, Arial, sans-serif',
    displayHugeBold: {
      ...FONT_STYLE_NORMAL,
      fontWeight: 700,
      fontSize: 72,
      lineHeight: '80px',
      color: colours.text.text,
      ...LETTER_SPACING_DEFAULT
    },
    displayLargeBold: {
      ...FONT_STYLE_NORMAL,
      fontWeight: 700,
      fontSize: 56,
      lineHeight: '62px',
      color: colours.text.text,
      ...LETTER_SPACING_QUARTER
    },
    displayMediumNorm: {
      ...FONT_STYLE_NORMAL,
      fontWeight: 500,
      fontSize: 26,
      lineHeight: '34px',
      color: colours.text.text,
      ...LETTER_SPACING_DEFAULT
    },
    h1: {
      normal: {
        ...FONT_STYLE_NORMAL,
        ...FONT_WEIGHT_NORMAL,
        fontSize: 40,
        lineHeight: '48px',
        color: colours.text.text,
        ...LETTER_SPACING_QUARTER
      },
      bold: {
        ...FONT_WEIGHT_BOLD
      },
      medium: {
        ...FONT_WEIGHT_MEDIUM
      }
    },
    h2: {
      normal: {
        ...FONT_STYLE_NORMAL,
        ...FONT_WEIGHT_NORMAL,
        fontSize: 32,
        lineHeight: '38px',
        color: colours.text.text,
        ...LETTER_SPACING_DEFAULT
      },
      bold: {
        ...FONT_WEIGHT_BOLD,
        ...LETTER_SPACING_QUARTER
      },
      medium: {
        ...FONT_WEIGHT_MEDIUM
      }
    },
    h3: {
      normal: {
        ...FONT_STYLE_NORMAL,
        ...FONT_WEIGHT_NORMAL,
        fontSize: 24,
        lineHeight: '32px',
        color: colours.text.text,
        ...LETTER_SPACING_DEFAULT
      },
      bold: {
        ...FONT_WEIGHT_BOLD,
        ...LETTER_SPACING_QUARTER
      },
      medium: {
        ...FONT_WEIGHT_MEDIUM,
        ...LETTER_SPACING_QUARTER
      }
    },
    body1: {
      normal: {
        ...FONT_STYLE_NORMAL,
        ...FONT_WEIGHT_NORMAL,
        fontSize: 18,
        lineHeight: '28px',
        color: colours.text.text,
        ...LETTER_SPACING_DEFAULT
      },
      bold: {
        ...FONT_WEIGHT_BOLD
      },
      medium: {
        ...FONT_WEIGHT_MEDIUM
      },
      link: {
        ...LINK_STYLE
      },
      altLink: {
        ...LINK_STYLE
      }
    },
    body2: {
      normal: {
        ...FONT_STYLE_NORMAL,
        ...FONT_WEIGHT_NORMAL,
        fontSize: 16,
        lineHeight: '24px',
        color: colours.text.text,
        ...LETTER_SPACING_DEFAULT
      },
      bold: {
        ...FONT_WEIGHT_BOLD
      },
      medium: {
        ...FONT_WEIGHT_MEDIUM
      },
      link: {
        ...LINK_STYLE
      },
      altLink: {
        ...LINK_STYLE
      }
    },

    // Corresponds to Body/Small
    subtitle1: {
      normal: {
        ...FONT_STYLE_NORMAL,
        ...FONT_WEIGHT_NORMAL,
        fontSize: 14,
        lineHeight: '22px',
        color: colours.text.text,
        ...LETTER_SPACING_DEFAULT
      },
      bold: {
        ...FONT_WEIGHT_BOLD
      },
      link: {
        ...LINK_STYLE
      },
      altLink: {
        ...LINK_STYLE
      }
    },

    // Corresponds to Body/Extra Small
    subtitle2: {
      normal: {
        ...FONT_STYLE_NORMAL,
        ...FONT_WEIGHT_NORMAL,
        fontSize: 12,
        lineHeight: '18px',
        color: colours.text.text,
        ...LETTER_SPACING_DEFAULT
      },
      bold: {
        ...FONT_WEIGHT_MEDIUM
      },
      link: {
        ...LINK_STYLE
      },
      altLink: {
        ...LINK_STYLE
      }
    },

    buttonLarge: {
      ...FONT_STYLE_NORMAL,
      ...FONT_WEIGHT_MEDIUM,
      fontSize: 18,
      lineHeight: '24px',
      color: colours.text.text,
      ...LETTER_SPACING_DEFAULT
    },
    buttonMedium: {
      ...FONT_STYLE_NORMAL,
      ...FONT_WEIGHT_MEDIUM,
      fontSize: 16,
      lineHeight: '24px',
      color: colours.text.text,
      ...LETTER_SPACING_DEFAULT
    },
    buttonSmall: {
      ...FONT_STYLE_NORMAL,
      ...FONT_WEIGHT_MEDIUM,
      fontSize: 16,
      lineHeight: '24px',
      color: colours.text.text,
      ...LETTER_SPACING_DEFAULT
    }
  };
};

const colours: Colours = getThemeColors();

const dimensions: Dimensions = getThemeDimensions();

const fontFace: FontFaceDetails[] = getFontFaceDetails();

const globalStyles: GlobalStyles = getGlobalStyles();

export const getDefaultTheme = () => {
  const customTypography = getThemeTypography();

  return {
    customTypography,
    colours,
    dimensions,
    fontFace,
    globalStyles,
    typography: {
      fontFamily: customTypography.fontFamily
    }
  };
};
