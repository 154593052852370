import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialState } from '../initialState';
import { ReduxState } from '../../core/types/ReduxStoreTypes';
import registerDevice from '../api/registerDevice';
import generateToken from '../api/generateToken';
import { RESET_AUTHORISATION_DETAILS } from '../ActionTypeConstants';

export const resetAuthorisationDetails = createAction<string>(RESET_AUTHORISATION_DETAILS);

const authorisationDetailsSlice = createSlice({
  name: 'authorisationDetailsSlice',
  initialState: initialState.authorisationDetails,
  reducers: {
    resetAuthorisationState: (state) => {
      const { registerDeviceLoading, generateTokenLoading, clientId, clientSecret, authorisationError } = initialState.authorisationDetails;

      state.registerDeviceLoading = registerDeviceLoading;
      state.generateTokenLoading = generateTokenLoading;
      state.clientId = clientId;
      state.clientSecret = clientSecret;
      state.authorisationError = authorisationError;
    }
  },
  extraReducers: (builder) => {
    // Register Device API
    builder
      .addCase(resetAuthorisationDetails, () => initialState.authorisationDetails)

      .addCase(registerDevice.pending, (state) => {
        state.registerDeviceLoading = 'loading';
        state.generateTokenLoading = 'idle';
        state.clientId = '';
        state.clientSecret = '';
        state.authorisationError = false;
      })
      .addCase(registerDevice.fulfilled, (state, action) => {
        const { client_id: clientId, client_secret: clientSecret } = action.payload || {};

        if (clientId && clientSecret) {
          state.registerDeviceLoading = 'succeeded';
          state.clientId = clientId;
          state.clientSecret = clientSecret;
        }
      })
      .addCase(registerDevice.rejected, (state, action) => {
        state.registerDeviceLoading = 'failed';
        state.authorisationError = true;
      });

    // Generate Token
    builder
      .addCase(generateToken.pending, (state) => {
        state.generateTokenLoading = 'loading';
        state.registerDeviceLoading = 'idle';
      })
      .addCase(generateToken.fulfilled, (state, action) => {
        const { access_token = false } = action.payload || {};
        if (access_token) {
          state.generateTokenLoading = 'succeeded';
        }
      })
      .addCase(generateToken.rejected, (state) => {
        state.generateTokenLoading = 'failed';
        state.authorisationError = true;
      });
  }
});

export const getAuthorisationDetails = (state: ReduxState) => state.authorisationDetails;

export const { resetAuthorisationState } = authorisationDetailsSlice.actions;

export default authorisationDetailsSlice.reducer;
