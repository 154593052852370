import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// RTK Slice
import { getReferenceData } from '../../redux/modules/referenceDataSlice';
// API Wrappers
import getReferralRewards from '../../redux/api/getReferralRewards';
// Types
import { AppDispatch } from '../../redux/store';
// Utils
import GetBasicDetails from '../utils/GetBasicDetails';
// Constants
import { LOCALE_LANG_CODE_MAPPING } from '../utils/Constants/Constants';

const useGetReferralRewardByLocale = (shouldShowRefererInfo: boolean = false) => {
    const dispatch: AppDispatch = useDispatch();

    const { referralRewardsData, selectedLanguage } = useSelector(getReferenceData);
    
    useEffect(() => {
        const { localeId } = GetBasicDetails(LOCALE_LANG_CODE_MAPPING[selectedLanguage]);
    
        if(referralRewardsData?.legalEntity && shouldShowRefererInfo) {
          const requestData = {
            legalEntity: referralRewardsData?.legalEntity,
            localeId: `${localeId}`
          };
    
          dispatch(getReferralRewards(requestData));
        }
      }, [selectedLanguage]);
};

export default useGetReferralRewardByLocale