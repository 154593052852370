// Components
import { InformationalBlurbContent } from '../../../components/molecules/RPInformationalBlurb/RPInformationalBlurb';
// Utils
import { ReferralRewardsValues } from '../FormatReferralRewards';
// Constants
import { CD_REGULATORY_INFORMATION_LINK } from '../Constants/Constants';

const GetInformationalBlurbContentArray = (informationalBlurbContentArray: Array<InformationalBlurbContent>, translate: Function, isLinkInContent: boolean = false, referralRewardsValues?: ReferralRewardsValues) => {

  /*return informationalBlurbContentArray.map((contentElement: InformationalBlurbContent) => {

    console.log('GetInformationalBlurbContentArray map contentElement: ', contentElement);

    if (contentElement.type === InformationalBlurbContentType.text) {
      return {
        ...contentElement,
        content: translate(contentElement.content)
      } as InformationalBlurbContent
    } else if (contentElement.type === InformationalBlurbContentType.link) {
      return {
        ...contentElement,
        content: translate(contentElement.content),
        href: translate(contentElement.href)
      } as InformationalBlurbContent
    }

    console.log('GetInformationalBlurbContentArray map contentElement after translation: ', contentElement);

    return contentElement;
  });*/
  
  return informationalBlurbContentArray.map((contentElement: InformationalBlurbContent) =>
    ({
      ...contentElement,
      content: translate(contentElement.content, referralRewardsValues),
      ...('href' in contentElement && {
        href: isLinkInContent ? CD_REGULATORY_INFORMATION_LINK : translate(contentElement.href)
      })
    })
  );
};

export default GetInformationalBlurbContentArray;