import { createAsyncThunk } from '@reduxjs/toolkit';

// Axios Wrapper
import axiosWrapper, { ObjectMapping } from './axiosWrapper';
// RTK Slice
import { getServicesErrorObj } from '../modules/errorDetailsSlice';

type getReferralRewardsRequestData = {
  legalEntity: string;
  localeId: string;
};

const getReferralRewards = createAsyncThunk(
  '/reference-data/referral-rewards',
  async (data: getReferralRewardsRequestData, thunkAPI) => {
    const { legalEntity, localeId } = data;

    const dataToReplace: ObjectMapping = {
      legalEntity,
      localeId
    };

    try {
      const response = await axiosWrapper('getReferralRewards', 'GET', thunkAPI, null, dataToReplace);

      return response && response.data;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  }
);

export default getReferralRewards;
