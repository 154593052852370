import { OrganizationDetailsEnvSpecific, LiveChatEnvSpecificType } from '../types/OrganizationTypes';
import { CoralogixBrowserSdkConfig } from '@coralogix/browser/src/types';
/*
 Read the current environment from the global window variable
 Set the appropriate global config for the environment variable
 Return this env config
 Default to UAT if no env is present
*/
const ENVIRONMENT_CONFIG: { [key: string]: {} } = {
  SIT: {
    gaConfig: 'SIT env config',
    basicAuthToken: {
      currenciesdirect: 'bDc5MTZjN2Q3YmJiZDQ0YTNjYjJkMTRiOTQ3ZTBmMzNiNDpkZTU5NjI1ZDRhNjQ0ZmMwODI5MDJkOThkMWQ5NzFhNA==',
      torfx: 'bDc5Zjc0MmJlMzBiMmI0OTRiOGJjYWY0NGQ5OWE3YzQzNjoyNzFhNDEwZDBkZjU0ZWNlOWUzM2JhMjMzMDZhMTFkNw==',
      torfxoz: 'YmU3YjNhNWItN2JjNC00ZTc5LTg0NmYtNjZlN2E4NjljM2FjOmRmNTkwNTZiLWY4NGQtNDhlMS1iNjEyLTM3ZjZjZTAzZDdlNg==',
      fcg: 'bDdlNzJjY2JhNDYxMDI0OTExODgzODkwYjYwYjhmNGYwODoyNjc1MjM2NmUwMWU0YWE1YTBjN2QyZWRlNmE0MDIzYg==',
      ramsdens: 'bDc3MWY0Y2JkMTA0ODA0OTQxYmViM2YwY2Q0MjBlM2VmMjplYzAxMjdiNDMyYmE0MmRhOGJiODI0MmFhYmUyMDNmYg==',
      hl: 'bDczYzY3ODk4M2RhNjI0MDcyOTJjNGE5YWI3ZDlhMzFlZjphM2NmZDc2OGRmYWM0OWE2OWY5N2JhZDQxZjU5NmMyYg==',
      propertypay: 'bDc5MTZjN2Q3YmJiZDQ0YTNjYjJkMTRiOTQ3ZTBmMzNiNDpkZTU5NjI1ZDRhNjQ0ZmMwODI5MDJkOThkMWQ5NzFhNA=='
    },
    landingPageURL: {
      currenciesdirect: 'https://online.sit.currenciesdirect.com/CustomerPortal/landing.html',
      torfx: 'https://online.sit.torfx.com/CustomerPortal/landing.html',
      torfxoz: 'https://online.sit.torfx.com.au/CustomerPortal/landing.html',
      fcg: 'https://online.sit.currenciesdirect.com/CustomerPortal/landing.html',
      ramsdens: 'https://online.sit.currenciesdirect.com/CustomerPortal/landing.html',
      hl: 'https://online.sit.currenciesdirect.com/CustomerPortal/landing.html',
      propertypay: 'https://register.sit.propertypay.redpincompany.com/landing-page/'
    },
    loginPageURL: {
      currenciesdirect: 'https://online.sit.currenciesdirect.com/CustomerPortal/login.html',
      torfx: 'https://online.sit.torfx.com/CustomerPortal/login.html',
      torfxoz: 'https://online.sit.torfx.com.au/CustomerPortal/login.html',
      fcg: 'https://online.sit.currenciesdirect.com/CustomerPortal/login.html',
      ramsdens: 'https://online.sit.currenciesdirect.com/CustomerPortal/login.html',
      hl: 'https://online.sit.currenciesdirect.com/CustomerPortal/login.html',
      propertypay: 'https://propertypay.sit.redpincompany.com/login/'
    },
    forgotPasswordURL: {
      currenciesdirect: 'https://online.sit.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      torfx: 'https://online.sit.torfx.com/CustomerPortal/forgotPassword.html',
      torfxoz: 'https://online.sit.torfx.com.au/CustomerPortal/forgotPassword.html',
      fcg: 'https://online.sit.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      ramsdens: 'https://online.sit.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      hl: 'https://online.sit.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      propertypay: 'https://propertypay.sit.redpincompany.com/login/'
    },
    liveChatConfig: {
      currenciesdirect: {
        eswURL: 'https://partners-cdfxservices--sit.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
        serviceURL: 'https://service.force.com',
        embdServiceDeplySbxURL: 'https://partners-cdfxservices--sit.sandbox.my.salesforce.com',
        chatWithAgentURL: 'https://partners-cdfxservices--sit.sandbox.my.salesforce-sites.com/ChatWithAgent',
        embdServiceDeplyId: '00D0D0000008dFv',
        embdServiceDeplyName: 'CD_PFX_Embedded_Service_Deployment',
        baseLiveAgentContentURL: 'https://c.la1-c1cs-cdg.salesforceliveagent.com/content',
        deploymentId: '5720D0000008OdX',
        buttonId: '5730D0000008OfB',
        baseLiveAgentURL: 'https://d.la1-c1cs-cdg.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I0D00000000KVUAY_17dc3280412'
      },
      torfxoz: {
        eswURL: 'https://partners-cdfxservices--sit.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
        serviceURL: 'https://service.force.com',
        embdServiceDeplySbxURL: 'https://partners-cdfxservices--sit.sandbox.my.salesforce.com',
        chatWithAgentURL: 'https://partners-cdfxservices--sit.sandbox.my.salesforce-sites.com/ChatWithAgent',
        embdServiceDeplyId: '00D0D0000008dFv',
        embdServiceDeplyName: 'Tor_AUS_PFX_Embedded_Service_Deployment',
        baseLiveAgentContentURL: 'https://c.la2s-core1.sfdc-cehfhs.salesforceliveagent.com/content',
        deploymentId: '5720D0000008OeL',
        buttonId: '5730D0000008Ogd',
        baseLiveAgentURL: 'https://d.la2s-core1.sfdc-cehfhs.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I0D00000000MCUAY_17e6c3777ea'
      }
    },
    googleAnalyticsConfig: {
      currenciesdirect: 'GTM-PXS5PQS',
      fcg: 'GTM-WLDBFGS',
      torfx: 'GTM-W37GM2N',
      torfxoz: 'GTM-KQKRFLF',
      ramsdens: '',
      hl: 'GTM-TCVQJJ6',
      propertypay: 'GTM-TXH9NVLQ'
    },
    coralogixConfig: {
      public_key: '',
      application: '',
      coralogixDomain: ''
    },
    CFXLoginURL: 'https://business.sit.currenciesdirect.com/businessportal/login.htm',
    CFXForgotPasswordURL: 'https://business.sit.currenciesdirect.com/businessportal/forgotPassword.htm',
    isOnfidoEnabled: true,
    registrationPortalCurrenciesDirectURL: 'https://pfxreg.sit.currenciesdirect.com/',
    branchIOKey: {
      currenciesdirect: 'key_test_beq2PQgXiMf3bqvvBLrThgglrAcOiTxs',
      torfx: 'key_test_aybDbrPWANI7J86WYZ1Mrogmtwo6APqC',
      torfxoz: 'key_test_evkuorP1Hbh5XYlcMCwnMmdoEul03V6E'
    },
    googleAnalyticsMeasurementId: {
      currenciesdirect: 'G-LD4936KJJ6',
      fcg: '',
      torfx: 'G-51GC8P5ZVE',
      torfxoz: 'G-8KWL6LVDG1',
      ramsdens: '',
      hl: '',
      propertypay: 'G-9W26MXTX1S'
    }
  },
  UAT: {
    gaConfig: 'UAT env config',
    basicAuthToken: {
      currenciesdirect: 'bDdhZWM0NGYwYmI1YjI0NzVlYTQ2ZjQ0NmIzZTA0MTc4NDoyN2NjYzNhOTdiYjc0YjljYjU4N2Y0ZWEyOWY3NTBiZg==',
      torfx: 'bDdhZjFkM2I3OWVmMmY0MmEzODFmNDBmYzdhM2MxYzNhMjo5ZjQ3NWEzODQ1ZTQ0YjJmYmMzMGQ5NzA0ZTcxMjMxMQ==',
      torfxoz: 'MmIxZGE5NGMtNWEyNi00NDUwLTgyYTQtZGQzMWQ3ZGJlYTZiOmRiMDM4NzAzLWJlMTMtNDI2Yy05ZTZlLTM5M2ViMWEyMTQ2YQ==',
      fcg: 'bDcwM2ZkMWFmNmYxYjA0ZDkyYjcxZjdmODY5OWQxODhmNzoyZWUzNGFiYzc5MGI0OTA1ODJiNWY5NmUxNTQ3NWE2NQ==',
      ramsdens: 'bDc1NGU1MjU4N2MyMjk0NTA4YTIzYTc1NjU2NWJmNDIzNTo1MDEzY2FiYTMwZWQ0NDMzYTYzYTcyMGM5N2Q4YjNiZA==',
      hl: 'bDdlNzQxODcwZDUxZTY0Yzk4OTdhZjRlYWFmYzI5ZjhlNTo3ZjZhYTVjYTk3NTU0OWIzODZjNWRkYjgxN2QwZDNlMQ==',
      propertypay: 'bDdhZWM0NGYwYmI1YjI0NzVlYTQ2ZjQ0NmIzZTA0MTc4NDoyN2NjYzNhOTdiYjc0YjljYjU4N2Y0ZWEyOWY3NTBiZg=='
    },
    landingPageURL: {
      currenciesdirect: 'https://online.uat.currenciesdirect.com/CustomerPortal/landing.html',
      torfx: 'https://online.uat.torfx.com/CustomerPortal/landing.html',
      torfxoz: 'https://online.uat.torfx.com.au/CustomerPortal/landing.html',
      fcg: 'https://online.uat.currenciesdirect.com/CustomerPortal/landing.html',
      ramsdens: 'https://online.uat.currenciesdirect.com/CustomerPortal/landing.html',
      hl: 'https://online.uat.currenciesdirect.com/CustomerPortal/landing.html',
      propertypay: 'https://register.uat.propertypay.redpincompany.com/landing-page/'
    },
    loginPageURL: {
      currenciesdirect: 'https://online.uat.currenciesdirect.com/CustomerPortal/login.html',
      torfx: 'https://online.uat.torfx.com/CustomerPortal/login.html',
      torfxoz: 'https://online.uat.torfx.com.au/CustomerPortal/login.html',
      fcg: 'https://online.uat.currenciesdirect.com/CustomerPortal/login.html',
      ramsdens: 'https://online.uat.currenciesdirect.com/CustomerPortal/login.html',
      hl: 'https://online.uat.currenciesdirect.com/CustomerPortal/login.html',
      propertypay: 'https://propertypay.uat.redpincompany.com/login/'
    },
    forgotPasswordURL: {
      currenciesdirect: 'https://online.uat.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      torfx: 'https://online.uat.torfx.com/CustomerPortal/forgotPassword.html',
      torfxoz: 'https://online.uat.torfx.com.au/CustomerPortal/forgotPassword.html',
      fcg: 'https://online.uat.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      ramsdens: 'https://online.uat.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      hl: 'https://online.uat.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      propertypay: 'https://propertypay.uat.redpincompany.com/login/'
    },
    liveChatConfig: {
      currenciesdirect: {
        eswURL: 'https://partners-cdfxservices--uat.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
        serviceURL: 'https://service.force.com',
        embdServiceDeplySbxURL: 'https://partners-cdfxservices--uat.sandbox.my.salesforce.com',
        chatWithAgentURL: 'https://partners-cdfxservices--uat.sandbox.my.salesforce-sites.com/ChatWithAgent',
        embdServiceDeplyId: '00D1X0000008aZY',
        embdServiceDeplyName: 'CD_PFX_Embedded_Service_Deployment',
        baseLiveAgentContentURL: 'https://c.la1-c1cs-fra.salesforceliveagent.com/content',
        deploymentId: '5721X0000004EF8',
        buttonId: '5731X0000004DIZ',
        baseLiveAgentURL: 'https://d.la1-c1cs-cdg.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000008PQKUA2_17dc2fcddd7'
      },
      torfxoz: {
        eswURL: 'https://partners-cdfxservices--uat.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
        serviceURL: 'https://service.force.com',
        embdServiceDeplySbxURL: 'https://partners-cdfxservices--uat.sandbox.my.salesforce.com',
        chatWithAgentURL: 'https://partners-cdfxservices--uat.sandbox.my.salesforce-sites.com/ChatWithAgent',
        embdServiceDeplyId: '00D1X0000008aZY',
        embdServiceDeplyName: 'Tor_AUS_PFX_Embedded_Service_Deployment',
        baseLiveAgentContentURL: 'https://c.la2s-core1.sfdc-cehfhs.salesforceliveagent.com/content',
        deploymentId: '5721X0000004EJP',
        buttonId: '5731X0000004DJw',
        baseLiveAgentURL: 'https://d.la2s-core1.sfdc-cehfhs.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000008PRNUA2_17e6cd78190'
      }
    },
    googleAnalyticsConfig: {
      currenciesdirect: 'GTM-PXS5PQS',
      fcg: 'GTM-WLDBFGS',
      torfx: 'GTM-W37GM2N',
      torfxoz: 'GTM-KQKRFLF',
      ramsdens: '',
      hl: 'GTM-TCVQJJ6',
      propertypay: 'GTM-TXH9NVLQ'
    },
    coralogixConfig: {
      public_key: 'cxth_AefoIBtReWPMRGvxEZhxRzpAc2X0QA',
      application: 'reg2-uat',
      coralogixDomain: 'EU2'
    },
    CFXLoginURL: 'https://business.uat.currenciesdirect.com/businessportal/login.htm',
    CFXForgotPasswordURL: 'https://business.uat.currenciesdirect.com/businessportal/forgotPassword.htm',
    isOnfidoEnabled: true,
    registrationPortalCurrenciesDirectURL: 'https://pfxreg.uat.currenciesdirect.com/',
    branchIOKey: {
      currenciesdirect: 'key_test_beq2PQgXiMf3bqvvBLrThgglrAcOiTxs',
      torfx: 'key_test_aybDbrPWANI7J86WYZ1Mrogmtwo6APqC',
      torfxoz: 'key_test_evkuorP1Hbh5XYlcMCwnMmdoEul03V6E'
    },
    googleAnalyticsMeasurementId: {
      currenciesdirect: 'G-LD4936KJJ6',
      fcg: '',
      torfx: 'G-51GC8P5ZVE',
      torfxoz: 'G-8KWL6LVDG1',
      ramsdens: '',
      hl: '',
      propertypay: 'G-9W26MXTX1S'
    }
  },
  PROD: {
    gaConfig: 'PROD env config',
    basicAuthToken: {
      currenciesdirect: 'bDc4OTYzMGE4MjViYTI0MzhjYTZlYTZhM2M1YmQyOGNkNDoxMDhhOWU2ZDRjYjY0NWMwODI3YzBlN2UwYmZhZDBhMw==',
      torfx: 'bDdlYTA0YTk4MGE0ZGI0OGU4OTI0MmUzYzU3YWE3NTY0Ywo6ODk3ZWVkZjY4NjFkNGZjOTgxYjY2ZTYwOWMxNGVhZDc=',
      torfxoz: 'MGEwMmFiZWItNzI4Mi00ZTc0LTlmODctZWExMjY5NWFlOGU3OmVjODg1OTMxLTIzZDAtNGUzMC1iNGZhLWU4NzQ2OTU5ZGU4Ng==',
      fcg: 'bDdjMDg2NjY0N2MxZGQ0Y2RlYTY5OGFhZmE4ZGIwYzAwZTo4Mzc5ODcyMTcwMmE0YWEyYTM3OTZhZDJkODkyNTcxZA==',
      ramsdens: 'bDdlODQwZWRmNWE0ZGU0ZjBmYTQyYTA2YjNjYjQ2YWQxOTo0N2RmYzJmMmY1ODY0YmMwOWFlYTliNjQ2MThiZGI0Zg==',
      hl: 'bDc5NWM2MDlkMTFmNTg0NjVjOWQ4MTcyY2Q2MTM0MzNhZDo5Njg1M2ExY2YzM2U0YmZiYjBlNzQzYTY3YTI4YThjZQ==',
      propertypay: 'bDc4OTYzMGE4MjViYTI0MzhjYTZlYTZhM2M1YmQyOGNkNDoxMDhhOWU2ZDRjYjY0NWMwODI3YzBlN2UwYmZhZDBhMw=='
    },
    landingPageURL: {
      currenciesdirect: 'https://online.currenciesdirect.com/CustomerPortal/landing.html',
      torfx: 'https://online.torfx.com/CustomerPortal/landing.html',
      torfxoz: 'https://online.torfx.com.au/CustomerPortal/landing.html',
      fcg: 'https://online.currenciesdirect.com/CustomerPortal/landing.html',
      ramsdens: 'https://online.currenciesdirect.com/CustomerPortal/landing.html',
      hl: 'https://online.currenciesdirect.com/CustomerPortal/landing.html',
      propertypay: 'https://register.propertypay.redpincompany.com/landing-page/'
    },
    loginPageURL: {
      currenciesdirect: 'https://online.currenciesdirect.com/CustomerPortal/login.html',
      torfx: 'https://online.torfx.com/CustomerPortal/login.html',
      torfxoz: 'https://online.torfx.com.au/CustomerPortal/login.html',
      fcg: 'https://online.currenciesdirect.com/CustomerPortal/login.html',
      ramsdens: 'https://online.currenciesdirect.com/CustomerPortal/login.html',
      hl: 'https://online.currenciesdirect.com/CustomerPortal/login.html',
      propertypay: 'https://propertypay.redpincompany.com/login/'
    },
    forgotPasswordURL: {
      currenciesdirect: 'https://online.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      torfx: 'https://online.torfx.com/CustomerPortal/forgotPassword.html',
      torfxoz: 'https://online.torfx.com.au/CustomerPortal/forgotPassword.html',
      fcg: 'https://online.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      ramsdens: 'https://online.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      hl: 'https://online.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      propertypay: 'https://propertypay.redpincompany.com/login/'
    },
    liveChatConfig: {
      currenciesdirect: {
        eswURL: 'https://partners-cdfxservices.my.salesforce.com/embeddedservice/5.0/esw.min.js',
        serviceURL: 'https://service.force.com',
        embdServiceDeplySbxURL: 'https://partners-cdfxservices.my.salesforce.com',
        chatWithAgentURL: 'https://live-cdtorfx.secure.force.com/ChatWithAgent',
        embdServiceDeplyId: '00D20000000CjVC',
        embdServiceDeplyName: 'CD_PFX_Embedded_Service_Deployment',
        baseLiveAgentContentURL: 'https://c.la3-c2-cdg.salesforceliveagent.com/content',
        deploymentId: '572670000004MXG',
        buttonId: '573670000004N3b',
        baseLiveAgentURL: 'https://d.la3-c2-cdg.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I67000000GmjVEAS_17de1a50dad'
      },
      torfxoz: {
        eswURL: 'https://partners-cdfxservices.my.salesforce.com/embeddedservice/5.0/esw.min.js',
        serviceURL: 'https://service.force.com',
        embdServiceDeplySbxURL: 'https://partners-cdfxservices.my.salesforce.com',
        chatWithAgentURL: 'https://partners-cdfxservices.my.salesforce-sites.com/ChatWithAgent',
        embdServiceDeplyId: '00D20000000CjVC',
        embdServiceDeplyName: 'Tor_AUS_PFX_Embedded_Service_Deployment',
        baseLiveAgentContentURL: 'https://c.la1-core1.sfdc-cehfhs.salesforceliveagent.com/content',
        deploymentId: '572670000004MXf',
        buttonId: '573670000004N40',
        baseLiveAgentURL: 'https://d.la1-core1.sfdc-cehfhs.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I67000000GmjpEAC_17eb9e889ab'
      }
    },
    googleAnalyticsConfig: {
      currenciesdirect: 'GTM-P7VD6VZ',
      fcg: 'GTM-PFDWSHJ',
      torfx: 'GTM-TKQJN4P',
      torfxoz: 'GTM-N4KSQQ7',
      ramsdens: '',
      hl: 'GTM-M8H2XMH',
      propertypay: 'GTM-WPNZQCS6'
    },
    coralogixConfig: {
      public_key: 'cxth_YhJJwgPYUnYjGR0zLuaq4EHq3UCRCU',
      application: 'reg2-prod',
      coralogixDomain: 'EU2'
    },
    CFXLoginURL: 'https://business.currenciesdirect.com/businessportal/login.htm',
    CFXForgotPasswordURL: 'https://business.currenciesdirect.com/businessportal/forgotPassword.htm',
    isOnfidoEnabled: true,
    registrationPortalCurrenciesDirectURL: 'https://pfxreg.currenciesdirect.com/',
    branchIOKey: {
      currenciesdirect: 'key_live_nitZOIlWiQjWgwtEwPCsYhjkAFlOeOBo',
      torfx: 'key_live_cCdEovK6rNQ1I13926WQrohkxug4tUFo',
      torfxoz: 'key_live_jrpwgwMWKna961cpQDBeyigexyn7WPYd'
    },
    googleAnalyticsMeasurementId: {
      currenciesdirect: 'G-RE8W60JW73',
      fcg: '',
      torfx: 'G-J61FXZ7HB5',
      torfxoz: 'G-0Q04M7QN0T',
      ramsdens: '',
      hl: '',
      propertypay: 'G-QN8HX5Z368'
    }
  },
  'UAT1-Z': {
    gaConfig: 'UAT1-Z env config',
    basicAuthToken: {
      currenciesdirect: 'bDdhZWM0NGYwYmI1YjI0NzVlYTQ2ZjQ0NmIzZTA0MTc4NDoyN2NjYzNhOTdiYjc0YjljYjU4N2Y0ZWEyOWY3NTBiZg==',
      torfx: 'bDdhZjFkM2I3OWVmMmY0MmEzODFmNDBmYzdhM2MxYzNhMjo5ZjQ3NWEzODQ1ZTQ0YjJmYmMzMGQ5NzA0ZTcxMjMxMQ==',
      torfxoz: 'MmIxZGE5NGMtNWEyNi00NDUwLTgyYTQtZGQzMWQ3ZGJlYTZiOmRiMDM4NzAzLWJlMTMtNDI2Yy05ZTZlLTM5M2ViMWEyMTQ2YQ==',
      fcg: 'bDcwM2ZkMWFmNmYxYjA0ZDkyYjcxZjdmODY5OWQxODhmNzoyZWUzNGFiYzc5MGI0OTA1ODJiNWY5NmUxNTQ3NWE2NQ==',
      ramsdens: 'bDc1NGU1MjU4N2MyMjk0NTA4YTIzYTc1NjU2NWJmNDIzNTo1MDEzY2FiYTMwZWQ0NDMzYTYzYTcyMGM5N2Q4YjNiZA==',
      hl: 'bDdlNzQxODcwZDUxZTY0Yzk4OTdhZjRlYWFmYzI5ZjhlNTo3ZjZhYTVjYTk3NTU0OWIzODZjNWRkYjgxN2QwZDNlMQ==',
      propertypay: 'bDdhZWM0NGYwYmI1YjI0NzVlYTQ2ZjQ0NmIzZTA0MTc4NDoyN2NjYzNhOTdiYjc0YjljYjU4N2Y0ZWEyOWY3NTBiZg=='
    },
    landingPageURL: {
      currenciesdirect: 'https://online.uat.currenciesdirect.com/CustomerPortal/landing.html',
      torfx: 'https://online.uat.torfx.com/CustomerPortal/landing.html',
      torfxoz: 'https://online.uat.torfx.com.au/CustomerPortal/landing.html',
      fcg: 'https://online.uat.currenciesdirect.com/CustomerPortal/landing.html',
      ramsdens: 'https://online.uat.currenciesdirect.com/CustomerPortal/landing.html',
      hl: 'https://online.uat.currenciesdirect.com/CustomerPortal/landing.html',
      propertypay: 'https://register.uat.propertypay.redpincompany.com/landing-page/'
    },
    loginPageURL: {
      currenciesdirect: 'https://online.uat.currenciesdirect.com/CustomerPortal/login.html',
      torfx: 'https://online.uat.torfx.com/CustomerPortal/login.html',
      torfxoz: 'https://online.uat.torfx.com.au/CustomerPortal/login.html',
      fcg: 'https://online.uat.currenciesdirect.com/CustomerPortal/login.html',
      ramsdens: 'https://online.uat.currenciesdirect.com/CustomerPortal/login.html',
      hl: 'https://online.uat.currenciesdirect.com/CustomerPortal/login.html',
      propertypay: 'https://propertypay.uat.redpincompany.com/login/'
    },
    forgotPasswordURL: {
      currenciesdirect: 'https://online.uat.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      torfx: 'https://online.uat.torfx.com/CustomerPortal/forgotPassword.html',
      torfxoz: 'https://online.uat.torfx.com.au/CustomerPortal/forgotPassword.html',
      fcg: 'https://online.uat.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      ramsdens: 'https://online.uat.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      hl: 'https://online.uat.currenciesdirect.com/CustomerPortal/forgotPassword.html',
      propertypay: 'https://propertypay.uat.redpincompany.com/login/'
    },
    liveChatConfig: {
      currenciesdirect: {
        eswURL: 'https://partners-cdfxservices--uat.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
        serviceURL: 'https://service.force.com',
        embdServiceDeplySbxURL: 'https://partners-cdfxservices--uat.sandbox.my.salesforce.com',
        chatWithAgentURL: 'https://partners-cdfxservices--uat.sandbox.my.salesforce-sites.com/ChatWithAgent',
        embdServiceDeplyId: '00D1X0000008aZY',
        embdServiceDeplyName: 'CD_PFX_Embedded_Service_Deployment',
        baseLiveAgentContentURL: 'https://c.la1-c1cs-fra.salesforceliveagent.com/content',
        deploymentId: '5721X0000004EF8',
        buttonId: '5731X0000004DIZ',
        baseLiveAgentURL: 'https://d.la1-c1cs-cdg.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000008PQKUA2_17dc2fcddd7'
      },
      torfxoz: {
        eswURL: 'https://partners-cdfxservices--uat.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
        serviceURL: 'https://service.force.com',
        embdServiceDeplySbxURL: 'https://partners-cdfxservices--uat.sandbox.my.salesforce.com',
        chatWithAgentURL: 'https://partners-cdfxservices--uat.sandbox.my.salesforce-sites.com/ChatWithAgent',
        embdServiceDeplyId: '00D1X0000008aZY',
        embdServiceDeplyName: 'Tor_AUS_PFX_Embedded_Service_Deployment',
        baseLiveAgentContentURL: 'https://c.la2s-core1.sfdc-cehfhs.salesforceliveagent.com/content',
        deploymentId: '5721X0000004EJP',
        buttonId: '5731X0000004DJw',
        baseLiveAgentURL: 'https://d.la2s-core1.sfdc-cehfhs.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1X0000008PRNUA2_17e6cd78190'
      }
    },
    googleAnalyticsConfig: {
      currenciesdirect: 'GTM-PXS5PQS',
      fcg: 'GTM-WLDBFGS',
      torfx: 'GTM-W37GM2N',
      torfxoz: 'GTM-KQKRFLF',
      ramsdens: '',
      hl: 'GTM-TCVQJJ6',
      propertypay: 'GTM-TXH9NVLQ'
    },
    coralogixConfig: {
      public_key: 'cxth_AefoIBtReWPMRGvxEZhxRzpAc2X0QA',
      application: 'reg2-uat',
      coralogixDomain: 'EU2'
    },
    CFXLoginURL: 'https://business.uat.currenciesdirect.com/businessportal/login.htm',
    CFXForgotPasswordURL: 'https://business.uat.currenciesdirect.com/businessportal/forgotPassword.htm',
    isOnfidoEnabled: true,
    registrationPortalCurrenciesDirectURL: 'https://pfxreg.uat.currenciesdirect.com/',
    branchIOKey: {
      currenciesdirect: 'key_test_beq2PQgXiMf3bqvvBLrThgglrAcOiTxs',
      torfx: 'key_test_aybDbrPWANI7J86WYZ1Mrogmtwo6APqC',
      torfxoz: 'key_test_evkuorP1Hbh5XYlcMCwnMmdoEul03V6E'
    },
    googleAnalyticsMeasurementId: {
      currenciesdirect: 'G-LD4936KJJ6',
      fcg: '',
      torfx: 'G-51GC8P5ZVE',
      torfxoz: 'G-8KWL6LVDG1',
      ramsdens: '',
      hl: '',
      propertypay: 'G-9W26MXTX1S'
    }
  }
};

// @ts-ignore
const CURRENT_ENV: string =
  // @ts-ignore
  window['SERVER_DATA'] && window['SERVER_DATA'] !== '__SERVER_DATA__' ? window['SERVER_DATA'] : 'UAT';

class EnvironmentConfig {
  currentEnvironmentConfig: { [key: string]: string } = ENVIRONMENT_CONFIG[CURRENT_ENV];

  constructor() {
    if (this.currentEnvironmentConfig) {
      // console.log('New instance cannot be created');
      // console.log('constructor currentEnvironmentConfig: ', this.currentEnvironmentConfig);
    }
  }

  getSpecificEnvConfigProperty(property: string) {
    return this.currentEnvironmentConfig[property];
  }

  getCurrentEnvConfig() {
    return this.currentEnvironmentConfig;
  }
}

const CurrentEnvironmentConfig = Object.freeze(new EnvironmentConfig());

export const getConfigProperty = (
  propertyName: string
): string | OrganizationDetailsEnvSpecific | LiveChatEnvSpecificType | CoralogixBrowserSdkConfig =>
  CurrentEnvironmentConfig.getSpecificEnvConfigProperty(propertyName);