// Types
import { OrganizationCodeTypes } from '../../types/OrganizationTypes';
import { LoadingState } from '../../types/ReduxStoreTypes';
import { ReferralRewardsType } from '../../types/ReferralRewardsTypes';
// Constants
import { DEFAULT_REFERRAL_REWARDS_VALUES } from '../Constants/Constants';

export type ReferralRewardsValues = {
  sendAmount: string;
  rewardAmount: string;
};

export const formatReferralRewards = (
  data: ReferralRewardsType,
  dataFetched: LoadingState,
  tenant: OrganizationCodeTypes,
  locale: string
): ReferralRewardsValues | undefined => {
  if (dataFetched === 'succeeded') {
    return {
      sendAmount: data.sendAmt,
      rewardAmount: data.rewardAmt
    };
  } else if (dataFetched === 'failed') {
    const defaultReferralReward = DEFAULT_REFERRAL_REWARDS_VALUES[tenant]!;
    return {
      sendAmount: defaultReferralReward?.sendAmt[locale],
      rewardAmount: defaultReferralReward?.rewardAmt[locale]
    };
  }
  return;
};
