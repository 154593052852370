import { isEmpty } from 'lodash';

import { InitiateAnalyticsCheck } from './InitiateAnalyticsCheck';
import { GoogleAnalyticsTagsMapping } from './GoogleAnalyticsTagsMapping';
import { EventAction, EventObject, EventLabel, EventUAObject, ActionKeys } from '../../types/GoogleAnalyticsTypes';

declare const window: any; // TODO - Replace appropriate type here

const triggerTag = (
  eventAction: EventAction,
  eventLabelGA4: string,
  eventLabelUA: string,
  dataLayerPayload: { [key in keyof EventObject]: string }
) => {
  window.dataLayer = window.dataLayer || [];
  //Call GA4 Tags
  const actionKeyName: string = eventAction.GA4;
  const eventActionGA4Key: ActionKeys = GoogleAnalyticsTagsMapping.eventActionKey[actionKeyName];

  let eventGA4Object: EventObject = {
    event: '',
    event_name: ''
  };

  eventGA4Object.event = eventAction.GA4;
  eventGA4Object.event_name = GoogleAnalyticsTagsMapping.eventName.pfx_registration_name.GA4;
  eventGA4Object[eventActionGA4Key] = eventLabelGA4;

  if (dataLayerPayload && !isEmpty(dataLayerPayload)) {
    eventGA4Object = { ...eventGA4Object, ...dataLayerPayload };
  }

  window.dataLayer.push(eventGA4Object);

  //Call UA Tags
  let eventUAObject: EventUAObject = {
    event_category: '',
    event_action: '',
    event_label: '',
    event: ''
  };

  if( GoogleAnalyticsTagsMapping.eventName.pfx_registration_name?.UA ) {
    eventUAObject.event_category = GoogleAnalyticsTagsMapping.eventName.pfx_registration_name?.UA;
    eventUAObject.event_action = eventAction.UA;
    eventUAObject.event_label = eventLabelUA;
    eventUAObject.event = eventAction.UA;

    window.dataLayer.push(eventUAObject);
  }
};

export const TriggerGoogleAnalyticsTag = (
  eventAction: EventAction,
  eventLabel: EventLabel,
  data: any = {},
  payload: any = {}
) => {
  let eventLabelGA4: string = eventLabel?.GA4;
  let eventLabelUA: string = eventLabel?.UA;

  if (data && !isEmpty(data)) {
    let currentDataKey = Object.keys(data)[0],
      currentDataValue: any = data[currentDataKey];
    eventLabelGA4 = eventLabel.GA4.replace('{' + currentDataKey + '}', currentDataValue);
    eventLabelUA = eventLabel?.UA?.replace('{' + currentDataKey + '}', currentDataValue);
  }

  InitiateAnalyticsCheck(window);

  if (eventAction.GA4 === GoogleAnalyticsTagsMapping.eventAction.load.GA4) {
    window.isAnalyticsLoaded
      .then((message: string) => {
        if (message === 'GA-LOADED') {
          triggerTag(eventAction, eventLabelGA4, eventLabelUA, payload);
        }
      })
      .catch((message: any) => {
        return message;
      });
  } else if (window.gaGlobal) {
    window.dataLayer = window.dataLayer || [];
    triggerTag(eventAction, eventLabelGA4, eventLabelUA, payload);
  }
};
