import { FC, useContext } from 'react';
import { styled } from '@mui/material/styles';

import RPDialog, { SizeProps } from './RPDialog';
import RPText from '../../atoms/RPText';
import { LanguageContext } from '../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

interface RPDocsRequiredDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  size?: SizeProps['size'];
  listSize?: number;
}

interface ContainerPRops {
  marginBottom: number;
}

interface HeadingProps extends SizeProps {}

interface ScrollContainerProps extends SizeProps {}

interface MainDescriptionProps extends SizeProps {}

const StyledBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size'
})<SizeProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    maxWidth: isSmallSize ? '275px' : '510px'
  };
});

const Heading = styled(RPText)<HeadingProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';
  return {
    marginBottom: '10px',
    ...(isSmallSize && {
      marginTop: '-6px',
      marginBottom: '5px',
      width: '250px'
    })
  };
});

const MainDescription = styled(RPText, {
  shouldForwardProp: (prop) => prop !== 'size'
})<MainDescriptionProps>(({ theme, size }) => {
  const {
    colours: { text }
  } = theme;
  const isSmallSize: boolean = size === 'small';
  const COLOUR_TEXT_TEXT_WEAK: HexColour = text.textWeak;

  return {
    marginBottom: isSmallSize ? '20px' : '30px',
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const ScrollContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size'
})<ScrollContainerProps>(({ theme, size }) => {
  const isSmallSize: boolean = size === 'small';
  const {
    colours: {
      scroll: { thumbBackground, trackBackground }
    }
  } = theme;

  const COLOUR_SCROLL_THUMB_BACKGROUND: HexColour = thumbBackground;
  const COLOUR_SCROLL_TRACK_BACKGROUND: HexColour = trackBackground;

  return {
    ...(isSmallSize && {
      maxHeight: '70vh',
      overflowY: 'auto',
      paddingRight: '5px',

      '&::-webkit-scrollbar': {
        width: 3
      },
      '&::-webkit-scrollbar-thumb': {
        background: COLOUR_SCROLL_THUMB_BACKGROUND,
        borderRadius: 4
      },
      '&::-webkit-scrollbar-track': {
        background: COLOUR_SCROLL_TRACK_BACKGROUND
      }
    })
  };
});

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'marginBottom'
})<ContainerPRops>(({ theme, marginBottom }) => {
  const {
    colours: { backgrounds }
  } = theme;

  const COLOUR_BG_BACKGROUND: HexColour = backgrounds.background;

  return {
    backgroundColor: COLOUR_BG_BACKGROUND,
    padding: '15px 20px',
    marginBottom
  };
});

const SubHeading = styled(RPText)(() => {
  return {
    marginBottom: '5px'
  };
});

const Description = styled(RPText)(({ theme }) => {
  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = text.textWeak;

  return {
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const StyledList = styled('ul')(({ theme }) => {
  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = text.textWeak;

  return {
    paddingInlineStart: '20px',
    listStyleType: 'disc',
    marginBottom: 0,
    marginTop: 15,

    li: {
      marginBottom: '5px',

      '::marker': {
        color: COLOUR_TEXT_TEXT_WEAK
      },

      ':last-child': {
        marginBottom: 0
      }
    }
  };
});

const RPDocsRequiredDialog: FC<RPDocsRequiredDialogProps> = (props: RPDocsRequiredDialogProps) => {
  const { isOpen, handleClose, size = 'normal', listSize = 5 } = props;

  const isSmallSize: boolean = size === 'small';
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const textVariant: 'subtitle1' | 'body2' = isSmallSize ? 'subtitle1' : 'body2';

  const body = (
    <StyledBody size={size} data-testid="dialog-body">
      <Heading variant={`${isSmallSize ? 'body1' : 'h3'}`} type="bold" text={translate('modal.docsRequired.heading')} size={size} />
      <ScrollContainer size={size}>
        <MainDescription variant={textVariant} type="normal" text={translate('modal.docsRequired.description')} size={size} />

        {/* Proof of identity */}
        <Container marginBottom={20}>
          <SubHeading variant={textVariant} type="bold" text={translate('modal.docsRequired.proofOfIdentity.subHeading')} />
          <Description variant="subtitle1" type="normal" text={translate('modal.docsRequired.proofOfIdentity.description')} />
        </Container>

        {/* Proof of address */}
        <Container marginBottom={30}>
          <SubHeading variant={textVariant} type="bold" text={translate('modal.docsRequired.proofOfAddress.subHeading')} />
          <Description variant="subtitle1" type="normal" text={translate('modal.docsRequired.proofOfAddress.description')} />
        </Container>

        {/* Important considerations */}
        <div>
          <RPText variant={textVariant} type="bold" text={translate('modal.docsRequired.importantConsiderations.subHeading')} />

          <StyledList>
            {Array(listSize)
              .fill('')
              .map((_, index: number) => {
                const description = translate(`modal.docsRequired.importantConsiderations.data.line${index + 1}`);
                return (
                  <li key={`line${index + 1}`} data-testid="imp-considerations">
                    <Description variant="subtitle1" type="normal" text={description} />
                  </li>
                );
              })}
          </StyledList>
        </div>
      </ScrollContainer>
    </StyledBody>
  );

  return <RPDialog isOpen={isOpen} handleClose={handleClose} body={body} size={size} />;
};

export default RPDocsRequiredDialog;
